<script>
import { useAuth0 } from '@auth0/auth0-vue';
import { usePendo } from '@/analytics/pendo';
import { useSentry } from '@/analytics/sentry';
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';

export default {
    setup() {
        const store = useStore();
        const auth = useAuth0();
        const pendo = usePendo();
        const sentry = useSentry();

        onMounted(() => {
            const user = auth.user;
            const isPartner = computed(() => store.getters['auth/isUserAPartner']);

            if (auth.isAuthenticated.value) {
                sentry.setUser({
                    casId: user.value?.sub,
                });

                pendo.initialize({
                    casId: user.value?.sub,
                    partner: isPartner.value,
                });
            }
        });
    },

    render() {
        return null;
    },
};
</script>
