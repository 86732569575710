import { IDLE } from '@/shared/constants/loadingStatuses.constants';

export default {
    appName: null,
    appType: null,
    companyName: null,
    error: null,
    inviteToken: null,
    fetchStatus: IDLE,
    linkStatus: IDLE,
};
