import { SUPPORTED_LOCALES } from './supported-locales';

const getLocaleLanguage = (locale) => {
    return locale.split('-')[0];
};

const getSupportedLocale = (preferredLocale) => {
    if (SUPPORTED_LOCALES.includes(preferredLocale)) {
        return preferredLocale;
    }

    const fallbackLocale = getLocaleLanguage(preferredLocale);

    if (SUPPORTED_LOCALES.includes(fallbackLocale)) {
        return fallbackLocale;
    }

    return 'en-us';
};

const getBrowserTimezone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export {
    getSupportedLocale,
    getBrowserTimezone,
};
