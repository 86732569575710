import { IDLE } from '@/shared/constants/loadingStatuses.constants';

export default {
    boot: {
        status: IDLE,
        error: null,
    },
    featureFlags: {},
    locale: null,
};
