import defaultState from './state';

export default {
    CHARGEBEE_INITIALIZED(state) {
        state.chargebeeInitialized = true;
    },

    SET_SUBSCRIPTION_INFO(state, payload) {
        state.subscriptionInfo = payload;
    },

    SET_SUBSCRIPTION_INFO_LOADING(state, payload) {
        state.subscriptionInfoLoading = payload;
    },

    SET_RENEWAL_ESTIMATE_LOADING(state, payload) {
        state.renewalEstimateLoading = payload;
    },

    SET_RENEWAL_ESTIMATE(state, payload) {
        state.renewalEstimate = payload;
    },

    SET_CANCEL_SCHEDULED_CHANGES_LOADING(state, value) {
        state.cancelScheduledChangesLoadingStatus = value;
    },

    SET_REMOVE_SCHEDULED_CANCELLATION_LOADING(state, value) {
        state.removeScheduledCancellationLoadingStatus = value;
    },

    SET_USAGES_LOADING(state, value) {
        state.usagesLoadingStatus = value;
    },

    SET_USAGES(state, payload) {
        state.usages = payload;
    },

    SET_LIMIT_ALERT_IGNORED(state) {
        state.contactLimitAlertsIgnored = true;
    },

    SET_STATE_TO_DEFAULT(state) {
        Object.assign(state, defaultState);
    },

    SET_HAS_MANAGE_BILLING(state, value) {
        state.hasManageBillingPermission = value;
    },

    SET_MANAGE_BILLING_PERMISSION_LOADING(state, value) {
        state.manageBillingPermissionLoading = value;
    },

    SET_ADDONS_PRICING_LOADING(state, value) {
        state.addonsPricingLoading = value;
    },

    SET_ADDONS_PRICING(state, value) {
        state.addonsPricing = value;
    },

    SET_SELECTED_CONTACTS_TIER(state, value) {
        state.selectedContactsTier = value;
    },

    SET_SELECTED_USERS_TIER(state, value) {
        state.selectedUsersTier = value;
    },

    SET_SELECTED_KTV_TIER(state, value) {
        state.selectedKtvTier = value;
    },

    SET_EDIT_SUBSCRIPTION_ADDONS_STAGE_LOADING(state, value) {
        state.editSubscriptionAddonsStageLoading = value;
    },
};

