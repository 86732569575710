import { auth } from '@/plugins/auth';

export default {
    isUserAPartner: () => {
        let isAPartner = false;
        const authorities = auth.user?.value?.authorities;

        if (Array.isArray(authorities)) {
            isAPartner = authorities.includes('ROLE_CAS_PARTNER_CRM');
        }

        return isAPartner;
    },
    isLoggedIn: () => {
        return auth.isAuthenticated.value;
    },
};
