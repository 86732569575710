import {
    ERROR,
    IDLE,
    LOADING,
    SUCCESS,
} from '@/shared/constants/loadingStatuses.constants';

export default {

    LOAD_PROFILE_START(state) {
        state.profile.loadingStatus = LOADING;
        state.profile.error = null;
    },

    LOAD_PROFILE_SUCCESS(state, { username, firstName, lastName }) {
        state.profile.username = username;
        state.profile.firstName = firstName;
        state.profile.lastName = lastName;
        state.profile.error = null;
        state.profile.loadingStatus = SUCCESS;
    },

    LOAD_PROFILE_EMAIL_SUCCESS(state, { mfaEmail }) {
        state.profile.mfaEmail = mfaEmail;
    },

    LOAD_PROFILE_NO_EMAIL(state) {
        state.profile.mfaEmail = null;
    },
    LOAD_PROFILE_SMS_SUCCESS(state, { mfaSms }) {
        state.profile.mfaSms = mfaSms;
    },

    LOAD_PROFILE_NO_SMS(state) {
        state.profile.mfaSms = null;
    },

    LOAD_PROFILE_APP_AUTH_SUCCESS(state, { mfaAppAuth }) {
        state.profile.mfaAppAuth = mfaAppAuth;
    },

    LOAD_PROFILE_NO_APP_AUTH(state) {
        state.profile.mfaAppAuth = null;
    },

    LOAD_PROFILE_SAVED_BROWSERS_SUCCESS(state, { browsers }) {
        state.profile.savedBrowsers = browsers;
    },

    LOAD_PROFILE_NO_SAVED_BROWSERS(state) {
        state.profile.savedBrowsers = [];
    },

    LOAD_PROFILE_INVITE_SUCCESS(state, { mfaInvited }) {
        state.profile.mfaInvited = mfaInvited;
    },

    LOAD_PROFILE_ERROR(state, error) {
        state.profile.error = error;
        state.profile.loadingStatus = ERROR;
    },

    UPDATE_PROFILE_START(state) {
        state.profile.error = null;
        state.profile.updatingStatus = LOADING;
    },

    UPDATE_PROFILE_SUCCESS(state, { username, firstName, lastName }) {
        state.profile.username = username;
        state.profile.firstName = firstName;
        state.profile.lastName = lastName;
        state.profile.error = null;
        state.profile.updatingStatus = SUCCESS;
    },

    UPDATE_PROFILE_ERROR(state, error) {
        state.profile.error = error;
        state.profile.updatingStatus = ERROR;
    },

    UPDATE_PASSWORD_START(state) {
        state.profile.error = null;
        state.profile.passwordStatus = LOADING;
    },

    UPDATE_PASSWORD_SUCCESS(state) {
        state.profile.error = null;
        state.profile.passwordStatus = IDLE;
    },

    UPDATE_PASSWORD_ERROR(state, error) {
        state.profile.error = error;
        state.profile.passwordStatus = ERROR;
    },

    UPDATE_PASSWORD_ERROR_CLEAR(state) {
        state.profile.error = null;
        state.profile.passwordStatus = IDLE;
    },

    UPDATE_MFA_START(state) {
        state.profile.error = null;
        state.profile.loadingStatus = IDLE;
        state.profile.mfaStatus = LOADING;
        state.profile.passwordStatus = IDLE;
    },

    DELETE_BROWSER_START(state) {
        state.profile.error = null;
        state.profile.browserStatus = LOADING;
    },

    DELETE_BROWSER_SUCCESS(state) {
        state.profile.error = null;
        state.profile.browserStatus = SUCCESS;
    },

    DELETE_BROWSER_ERROR(state, error) {
        state.profile.error = error;
        state.profile.browserStatus = ERROR;
    },

    UPDATE_MFA_SUCCESS(state) {
        state.profile.error = null;
        state.profile.mfaStatus = IDLE;
    },

    UPDATE_MFA_ERROR(state, error) {
        state.profile.error = error;
        state.profile.mfaStatus = ERROR;
    },

    GET_CODES_START(state) {
        state.profile.error = null;
        state.profile.codesStatus = LOADING;
        state.profile.scratchCodes = null;
        state.profile.codesCreatedOn = null;
    },

    GET_CODES_SUCCESS(state, { scratchCodes, codesCreatedOn }) {
        state.profile.error = null;
        state.profile.codesStatus = IDLE;
        state.profile.scratchCodes = scratchCodes;
        state.profile.codesCreatedOn = codesCreatedOn;
    },

    GET_CODES_ERROR(state, error) {
        state.profile.error = error;
        state.profile.codesStatus = ERROR;
        state.profile.scratchCodes = null;
        state.profile.codesCreatedOn = null;
    },
    DOWNLOAD_START(state) {
        state.profile.error = null;
    },
    DOWNLOAD_SUCCESS(state) {
        state.profile.error = null;
    },
    DOWNLOAD_ERROR(state, error) {
        state.profile.error = error;
    },
    SET_ACCOUNT_LOCKED(state, value) {
        state.profile.isAccountLocked = value;
    },
};
