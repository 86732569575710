import { IDLE } from '@/shared/constants/loadingStatuses.constants';

export default {
    oauth: {
        clientName: null,
        description: null,
        redirectUri: null,
        oauthLoading: IDLE,
        oauthUpdating: IDLE,
    },
};
