import { createStore as _createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import accounts from './accounts';
import auth from './auth';
import global from './global';
import invites from './invites';
import profile from './profile';
import oauth from './oauth';
import subscriptionManagement from './subscriptionManagement';

export const modules = {
    accounts,
    auth,
    global,
    invites,
    oauth,
    profile,
    subscriptionManagement,
};

const vuexPersistPathsAllowList = [
    'global.locale',
    'accounts.accounts.criteria.pageSize',
];

const localStorageKey = `account-central-${import.meta.env.VITE_ENV}`;

export const createStore = () => {
    const store = _createStore({
        modules,
        plugins: [
            createPersistedState({
                key: localStorageKey,
                paths: vuexPersistPathsAllowList,
            }),
        ],
    });

    return store;
};
