import { SUCCESS } from '@/shared/constants/loadingStatuses.constants';

export default {

    getCompanyName: (state) => {
        if (state.fetchStatus === SUCCESS) {
            return state.companyName;
        }

        return '';
    },

    getRedirectUrl: (state) => {
        let url = '';

        if (state.linkStatus === SUCCESS) {
            if (!state.appType || !state.appName) {
                return '';
            }

            if (state.appType === 'KEAP') {
                url = `${import.meta.env.VITE_KEAP_URL}/logout?app_id=${state.appName}&checkCas=true`;
            } else {
                url = `${import.meta.env.VITE_CORE_URL_PATTERN.replace('{id}', state.appName)}`;
            }
        }

        return url;
    },
};
