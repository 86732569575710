import { createRouter as _createRouter, createWebHistory } from 'vue-router';

import routes from '@/router/routes';
import { beforeEachRoute, beforeResolveRoute, onAfterEach } from '@/router/utils/router.util';
import { handleError } from '@/shared/utils/error.util';
import { useStore } from 'vuex';

export const createRouter = () => {
    const router = _createRouter({
        history: createWebHistory(),
        scrollBehavior: (_, __, savedPosition) => {
            if (savedPosition) {
                return savedPosition;
            }

            return { top: 0, left: 0 };
        },
        routes,
    });

    router.beforeEach((to, from, next) => {
        const store = useStore();
        return beforeEachRoute(to, from, next, store);
    });

    router.beforeResolve((to, from, next) => {
        const store = useStore();
        return beforeResolveRoute(to, from, next, store);
    });
    router.afterEach((to, from, failure) => {
        const store = useStore();
        return onAfterEach(to, from, failure, store);
    });
    router.onError(handleError);

    return router;
};
