import {
    LOADING,
    SUCCESS,
    ERROR,
} from '@/shared/constants/loadingStatuses.constants';

export default {
    LOAD_BOOT_START(state) {
        state.boot.status = LOADING;
        state.boot.error = null;
    },

    LOAD_BOOT_SUCCESS(state, { featureFlags, locale }) {
        state.boot.status = SUCCESS;
        state.boot.error = null;
        state.featureFlags = featureFlags;
        state.locale = locale;
    },

    LOAD_BOOT_ERROR(state, error) {
        state.boot.status = ERROR;
        state.boot.error = error;
    },
};
