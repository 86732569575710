import isEqual from 'lodash.isequal';
import {
    ERROR, IDLE, LOADING, SUCCESS,
} from '@/shared/constants/loadingStatuses.constants';
import { DEFAULT_ACCOUNT_ORDER_BY, DEFAULT_ACCOUNT_PAGE_SIZE } from './state';

export default {
    LOAD_ACCOUNTS_START(state, {
        pageSize = DEFAULT_ACCOUNT_PAGE_SIZE,
        searchText = null,
        orderBy = DEFAULT_ACCOUNT_ORDER_BY,
        pageNumber = 0,
        reload = false,
    }) {
        const criteria = { pageSize, searchText, orderBy };
        const startNewSearch = reload || !isEqual(state.accounts.criteria, criteria);

        if (startNewSearch) {
            state.accounts.filteredSize = null;
            state.accounts.pageTokens = [null];
            state.accounts.pageNumber = 0;
            state.accounts.criteria = criteria;
        } else if (pageNumber >= state.accounts.pageTokens.length) {
            state.accounts.pageNumber = state.accounts.pageTokens.length - 1;
        } else {
            state.accounts.pageNumber = pageNumber;
        }

        if (state.accounts.pageNumber < 0) {
            state.accounts.pageNumber = 0;
        }

        state.accounts.values = [];
        state.accounts.error = null;
        state.accounts.status = LOADING;
    },

    LOAD_ACCOUNTS_SUCCESS(state, {
        accounts, nextPageToken, totalSize, searchText, includesTotalCount, pageNumber,
    }) {
        state.accounts.values = accounts;
        state.accounts.error = null;
        state.accounts.status = SUCCESS;
        state.accounts.filteredSize = totalSize;
        state.accounts.lastSearchText = searchText;
        state.accounts.pageTokens.length = pageNumber + 1;
        state.accounts.pageTokens.push(nextPageToken);

        if (includesTotalCount) { // only set when the search has no filter and no pageToken
            state.accounts.totalSize = totalSize;
        }
    },

    LOAD_ACCOUNTS_ERROR(state, error) {
        state.accounts.values = [];
        state.accounts.error = error;
        state.accounts.status = ERROR;
    },

    REMOVE_ACCOUNT_START(state) {
        state.removal.status = LOADING;
    },

    REMOVE_ACCOUNT_SUCCESS(state) {
        state.removal.tenant = '';
        state.removal.status = SUCCESS;
    },

    REMOVE_ACCOUNT_FAILURE(state, error) {
        state.removal.status = ERROR;
        state.removal.error = error;
    },

    OPEN_REMOVE_MODAL(state, name) {
        state.removal.status = IDLE;
        state.removal.modalOpen = true;
        state.removal.name = name.name;
    },

    CLOSE_REMOVE_MODAL(state) {
        state.removal.modalOpen = false;
    },
};
