import { IDLE } from '@/shared/constants/loadingStatuses.constants';

export const DEFAULT_ACCOUNT_PAGE_SIZE = 50;
export const DEFAULT_ACCOUNT_ORDER_BY = ['account.companyProfile.name:ASC', 'account.legacyId:ASC'];
export default {
    accounts: {
        values: [],
        error: null,
        status: IDLE,
        filteredSize: null, // set on the first page of every search
        totalSize: null, // only set when the search has no filter
        lastSearchText: null, // set on every search
        pageNumber: 0,
        pageTokens: [],
        criteria: { // any change to this object results in a pageNumber reset
            pageSize: DEFAULT_ACCOUNT_PAGE_SIZE,
            searchText: null,
            orderBy: DEFAULT_ACCOUNT_ORDER_BY,
        },
    },
    removal: {
        status: IDLE,
        modalOpen: false,
        name: '',
        error: null,
    },
};
