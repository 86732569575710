<template>
    <div>
        {{ fullName }}
    </div>
</template>

<script>
import { useAuth0 } from '@auth0/auth0-vue';

export default {
    setup() {
        const auth = useAuth0();

        return { auth };
    },
    data() {
        return {
            user: this.auth.user,
        };
    },

    computed: {
        fullName() {
            return `${this.user.given_name} ${this.user.family_name}`;
        },
    },
};
</script>
