import { i18n } from '../../i18n';

/**
 * Adds commas to the provided number, ensures 2 decimals and prepends the provided currency symbol.
 * @param {number} value
 * @param {string} currencySymbol
 * @returns {string}
 */
export const formatCurrency = (value, currencySymbol) => {
    const { n } = i18n.global;

    if (value === null || value === undefined) {
        return '';
    }

    // Not using vue i18n's $number currency formatter because we are supplying the currency code
    return `${currencySymbol}${n(value, 'decimalTwoFractialDigits')}`;
};

/**
 * Adds commas to the number
 * @param {number} value
 * @returns {string}
 */
export const formatCommas = (value) => {
    const { n } = i18n.global;

    if (value === null || value === undefined) {
        return '';
    }

    return n(value);
};
