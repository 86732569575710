const CHUNK_LOAD_PATTERN = /loading chunk .+ failed/i;

const HTTP_ERRORS = {
    UNAUTHORIZED: 'UNAUTHORIZED',
};

const isChunkError = (err) => {
    if (typeof err === 'string') {
        return CHUNK_LOAD_PATTERN.test(err);
    }

    if (err instanceof Error) {
        return CHUNK_LOAD_PATTERN.test(err.message);
    }

    return false;
};

const tryHandleChunkLoadFailure = () => {
    // In private mode, some browsers will throw when attempting
    // to access local storage.
    try {
        const key = 'keap::failed-chunk-load';
        let tries = localStorage.getItem(key);

        tries = tries != null ? parseInt(tries, 10) : 0;

        if (!window.isNaN(tries) && tries < 5) {
            localStorage.setItem(key, tries + 1);
            window.location.reload(true);
        }
    } catch (error) { // eslint-disable-line no-unused-vars
        window.location.reload(true);
    }
};

export const handlePromiseRejections = () => {
    const oldUnhandledRejection = window.onunhandledrejection;

    window.onunhandledrejection = (event) => {
        if (isChunkError(event.reason) && typeof event.preventDefault === 'function') {
            tryHandleChunkLoadFailure();
            event.preventDefault();
        } else if (typeof oldUnhandledRejection === 'function') {
            oldUnhandledRejection.apply(window);
        }
    };
};

export const handleError = (err) => {
    if (isChunkError(err)) {
        return tryHandleChunkLoadFailure();
    }

    throw err;
};

export const processGraphqlErrorCode = (error) => {
    let processedError = error;

    const hasErrors = Array.isArray(error.graphQLErrors)
        && error.graphQLErrors[0]?.extensions?.code === HTTP_ERRORS.UNAUTHORIZED;

    if (hasErrors) {
        processedError = new Error(error.graphQLErrors[0].message);
        processedError.code = HTTP_ERRORS.UNAUTHORIZED;
    }

    return processedError;
};
