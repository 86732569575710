export const SUPPORTED_LOCALES = [
    'en-au',
    'en-ca',
    'en-gb',
    'en-ie',
    'en-il',
    'en-in',
    'en-nz',
    'en-sg',
    'es-do',
    'es-mx',
    'es-us',
    'es',
    'fr-ca',
    'fr-ch',
    'fr',
    'pt-br',
    'pt',
];
