import axios from 'axios';
import * as FEATURE_FLAGS from '@/shared/constants/featureFlag.constants';

const FEATURE_FLAG_NAMES = Object.keys(FEATURE_FLAGS)
    .map((key) => FEATURE_FLAGS[key])
    .filter((key) => typeof key === 'string');

export const loadFeatureFlags = async () => {
    let featureFlagsMap = {};

    try {
        if (FEATURE_FLAG_NAMES && FEATURE_FLAG_NAMES.length > 0) {
            const { data } = await axios.post(
                `${import.meta.env.VITE_FEATURE_FLAG_SERVICE_URL}/api/v4/featureFlag`,
                {
                    accountId: 'account-central-vue',
                    featureFlagNames: FEATURE_FLAG_NAMES,
                    platform: 'web',
                    version: '0.0.0', // doesn't matter unless we create flags to target a specific version
                },
            );

            const { featureFlags = {} } = data;

            for (const flag in featureFlags) {
                if (featureFlags[flag].enabled) {
                    featureFlagsMap[flag] = true;
                }
            }
        }
    } catch (error) { // eslint-disable-line no-unused-vars
        featureFlagsMap = {};
    }

    return featureFlagsMap;
};
