<template>
    <div class="boot-load-error">
        <EmptyState
            :title="$t('errors.general')"
            image="oops"
        >
            <i18n-t tag="p" keypath="bootLoadError.logInQuestion">
                <a
                    href="/logout?renew=true"
                >{{ $t('bootLoadError.loggingIn') }}
                </a>
            </i18n-t>
        </EmptyState>
    </div>
</template>

<script>
import EmptyState from '@/components/EmptyState.vue';

export default {
    components: {
        EmptyState,
    },
};
</script>

<style lang="scss" scoped>
    .boot-load-error {
        padding: $spacing-400;
        text-align: center;
    }

    h2 {
        margin: $spacing-300 0 $spacing-300;
    }

    h4 {
        margin-bottom: $spacing-400;
    }
</style>
