import { createI18n } from 'vue-i18n';
import moment from 'moment';

import defaultMomentLocale from './defaultMomentLocale.json';
import defaultMessages from './en-us.json';
import numberFormats from './number.json';
import {
    getSupportedLocale,
    getBrowserTimezone,
} from './utils';

moment.updateLocale('en', {
    longDateFormat: {
        LT: 'h:mm a',
        L: 'MMM D',
        LL: 'MM/DD/YYYY',
        LLL: 'MMM D, YYYY',
        LLLL: 'MMM D, YYYY LT',

        ll: 'ddd, MMM D',
        lll: 'ddd, MMM D, YYYY',
        llll: 'ddd, MMM D, YYYY LT',
    },
});

const i18n = createI18n({
    legacy: true,
    allowComposition: true,
    locale: 'en-us',
    fallbackLocale: 'en-us',
    numberFormats,
    silentTranslationWarn: false,
    silentFallbackWarn: true,
    messages: {
        'en-us': defaultMessages,
    },
    missing: () => {},
});

const formatNumber = i18n.global.n;
const translate = i18n.global.t;
const translateConditional = i18n.global.tc;

const n = (value, ...args) => {
    try {
        return formatNumber(Number(value), ...args);
    } catch {
        return Number.NaN.toString();
    }
};

const t = (...args) => {
    try {
        return translate(...args);
    } catch {
        return '';
    }
};

const tc = (...args) => {
    try {
        return translateConditional(...args);
    } catch {
        return '';
    }
};

i18n.global.n = n;
i18n.global.t = t;
i18n.global.tc = tc;
i18n.t = i18n.global.t;
i18n.tc = i18n.global.tc;
i18n.te = i18n.global.te;

const loadLocale = async () => {
    const locale = getSupportedLocale(navigator.language.toLowerCase());

    if (locale !== 'en-us') {
        const { messages, rtl } = await import(`./locales/${locale}.js`);

        moment.locale(locale);

        if (messages) {
            i18n.setLocaleMessage(locale, messages);
            i18n.locale = locale;
        }

        if (rtl) {
            document.documentElement.setAttribute('dir', 'rtl');
        }
    }

    return locale;
};

export {
    i18n,
    loadLocale,
    getBrowserTimezone,
    defaultMomentLocale,
};
