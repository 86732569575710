import { START_LOCATION } from 'vue-router';

import { i18n } from '@/i18n';
import amplitude from '@/analytics/amplitude';
import { PAGE_VIEWED } from '@/analytics/constants/amplitude-events.constants';


export const setTitle = (route) => {
    document.title = i18n
        ? i18n.t(route.meta.title || 'app.title')
        : '';
};

export const beforeEachRoute = async (to, from, next) => {
    // TODO: Auth - Figure out login metric

    const isInitialLoad = !from || from === START_LOCATION;

    if (!isInitialLoad) {
        window.performance.clearMarks(`transition_${to.name}_start`);
        window.performance.mark(`transition_${to.name}_start`);
        window.performance.mark('transition_start');
    }

    return next();
};

export const beforeResolveRoute = async (to, _, next, store) => {
    setTitle(to, store);

    amplitude.logEvent(PAGE_VIEWED, { Page: to.name });

    return next();
};

export const onAfterEach = (to) => {
    setTitle(to);
};
