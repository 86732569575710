import {
    ERROR,
    LOADING,
    SUCCESS,
} from '@/shared/constants/loadingStatuses.constants';

export default {
    LOAD_CLIENT_INFO_START(state) {
        state.oauth.oauthLoading = LOADING;
    },

    LOAD_CLIENT_INFO_SUCCESS(state, { clientName, description }) {
        state.oauth.clientName = clientName;
        state.oauth.description = description;
        state.oauth.oauthLoading = SUCCESS;
    },

    LOAD_CLIENT_INFO_ERROR(state) {
        state.oauth.oauthLoading = ERROR;
    },

    GET_AUTH_CODE_START(state) {
        state.oauth.oauthUpdating = LOADING;
    },

    GET_AUTH_CODE_SUCCESS(state, { redirectUri }) {
        state.oauth.oauthUpdating = SUCCESS;
        state.oauth.redirectUri = redirectUri;
    },

    GET_AUTH_CODE_ERROR(state, { code, message }) {
        state.oauth.oauthUpdating = ERROR;
        state.oauth.redirectUri = `${state.oauth.redirectUri}?error=${code}&error_description=${message}`;
    },

    SET_REDIRECT_URI(state, { redirectUrl }) {
        state.oauth.redirectUri = redirectUrl;
    },
};
