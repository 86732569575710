<template>
    <section
        class="app-placeholder"
        data-qa="app-placeholder-spinner"
    >
        <div class="spinner-wrapper">
            <DsLogo
                data-qa="logo"
                class="logo"
                icon-only
            />

            <DsSpinner :size="120" data-qa="spinner" />
        </div>
    </section>
</template>

<script>
import { DsLogo, DsSpinner } from '@infusionsoft/design-system';

export default {
    components: {
        DsLogo,
        DsSpinner,
    },
};
</script>

<style lang="scss" scoped>
    .app-placeholder {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
    }

    .logo {
        --logo-width: #{px-to-rem(30px)};
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .spinner-wrapper {
        position: relative;
    }
</style>
