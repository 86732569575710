import {
    IDLE, LOADING, SUCCESS, ERROR,
} from '@/shared/constants/loadingStatuses.constants';

export default {
    RETRIEVE_INVITE_DATA_START(state, { appName, inviteToken }) {
        state.appName = appName;
        state.appType = null;
        state.inviteToken = inviteToken;
        state.error = null;
        state.fetchStatus = LOADING;
        state.linkStatus = IDLE;
    },

    RETRIEVE_INVITE_DATA_SUCCESS(state, { companyName }) {
        state.companyName = companyName;
        state.fetchStatus = SUCCESS;
    },

    RETRIEVE_INVITE_DATA_FAILURE(state) {
        state.fetchStatus = ERROR;
    },

    LINK_ACCOUNT_START(state, { appName, inviteToken }) {
        state.appName = appName;
        state.appType = null;
        state.inviteToken = inviteToken;
        state.companyName = null;
        state.error = null;
        state.fetchStatus = IDLE;
        state.linkStatus = LOADING;
    },

    LINK_ACCOUNT_SUCCESS(state, { appType }) {
        state.appType = appType;
        state.linkStatus = SUCCESS;
    },

    LINK_ACCOUNT_FAILURE(state, { error }) {
        state.error = error;
        state.linkStatus = ERROR;
    },
};
