import { IDLE } from '@/shared/constants/loadingStatuses.constants';

export default {
    profile: {
        username: null,
        firstName: null,
        lastName: null,
        error: null,
        loadingStatus: IDLE,
        updatingStatus: IDLE,
        mfaAppAuth: null,
        mfaEmail: null,
        mfaInvited: null,
        mfaStatus: IDLE,
        mfaSms: null,
        passwordStatus: IDLE,
        isAccountLocked: false,
        scratchCodes: [],
        codesCreatedOn: null,
        codesStatus: IDLE,
        savedBrowsers: [],
        browserStatus: IDLE,
    },
};
