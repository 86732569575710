import axios from 'axios';
import { auth } from '@/plugins/auth';

const setupAxiosRequestInterceptor = (store) => {
    axios.interceptors.request.use(async (config) => {
        let accessToken;

        try {
            accessToken = await auth.getAccessTokenSilently();
        } catch (error) { // eslint-disable-line no-unused-vars
            console.warn('Not authenticated, proceeding without authentication.');
        }

        const { state } = store;
        const hasInviteToken = Boolean(state.invites.inviteToken);

        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }
        if (hasInviteToken) {
            config.headers['x-invite-token'] = state.invites.inviteToken;
        }

        window.performance.mark(`xhr_start_${config.url}_${config.method}`);
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
};

export const setupInterceptors = (store) => {
    setupAxiosRequestInterceptor(store);
};
