import axios from 'axios';
import moment from 'moment';
import { getSentry } from '@/analytics/sentry';

export default {
    CLEAR_MFA_STATUS(context, payload) {
        return clearMfaStatus(context, payload);
    },

    ENROLL_IN_MFA(context, payload) {
        return enrollInMfa(context, payload);
    },

    CLEAR_PASSWORD_ERROR(context, payload) {
        return clearPasswordError(context, payload);
    },

    LOAD_USER_PROFILE(context, payload) {
        return loadUserProfile(context, payload);
    },

    REMOVE_MFA(context, payload) {
        return removeMfa(context, payload);
    },

    SET_PRIMARY(context, payload) {
        return setPrimary(context, payload);
    },

    DELETE_SAVED_BROWSER(context, payload) {
        return deleteSavedBrowser(context, payload);
    },

    UPDATE_USER_PROFILE(context, payload) {
        return updateUserProfile(context, payload);
    },

    UPDATE_USER_PASSWORD(context, payload) {
        return updateUserPassword(context, payload);
    },

    GET_SCRATCH_CODES(context, payload) {
        return getScratchCodes(context, payload);
    },
    CLEAR_CODES_STATUS(context, payload) {
        return clearCodesStatus(context, payload);
    },

    DOWNLOAD_SCRATCH_CODES(context, payload) {
        return downloadCodes(context, payload);
    },
};

const clearPasswordError = async ({ commit }) => {
    commit('UPDATE_PASSWORD_ERROR_CLEAR');
};

const clearMfaStatus = async ({ commit }) => {
    commit('UPDATE_MFA_SUCCESS');
};

const enrollInMfa = async ({ commit }, { mfaType }) => {
    try {
        commit('UPDATE_MFA_START');

        const url = `${import.meta.env.VITE_ACCOUNT_CENTRAL_URL}/user/me/mfa`;

        const passwordBody = {};

        passwordBody.mfaType = mfaType;

        await axios.post(url, passwordBody);

        commit('UPDATE_MFA_SUCCESS');
    } catch (error) {
        const sentry = getSentry();
        sentry.captureException(error);

        commit('UPDATE_MFA_ERROR', error);
        throw error;
    }
};

const loadUserProfile = async ({ commit }) => {
    try {
        commit('LOAD_PROFILE_START');
        const url = `${import.meta.env.VITE_ACCOUNT_CENTRAL_URL}/user/me`;
        const response = await axios.get(url);
        const { firstName, lastName, username } = response.data;

        const { mfaProfile } = response.data;

        if (mfaProfile?.mfaRegistered?.mfaInvited) {
            const { mfaInvited } = mfaProfile.mfaRegistered;
            commit('LOAD_PROFILE_INVITE_SUCCESS', { mfaInvited });
        }

        if (mfaProfile?.mfaRegistered?.mfaEmail) {
            const { mfaEmail } = mfaProfile.mfaRegistered;

            commit('LOAD_PROFILE_EMAIL_SUCCESS', { mfaEmail });
        } else {
            commit('LOAD_PROFILE_NO_EMAIL');
        }

        if (mfaProfile?.mfaRegistered?.mfaSms) {
            const { mfaSms } = mfaProfile.mfaRegistered;

            commit('LOAD_PROFILE_SMS_SUCCESS', { mfaSms });
        } else {
            commit('LOAD_PROFILE_NO_SMS');
        }

        if (mfaProfile?.mfaRegistered?.mfaAppAuth) {
            const { mfaAppAuth } = mfaProfile.mfaRegistered;

            commit('LOAD_PROFILE_APP_AUTH_SUCCESS', { mfaAppAuth });
        } else {
            commit('LOAD_PROFILE_NO_APP_AUTH');
        }

        if (mfaProfile?.mfaSavedBrowsers?.browsers
            && mfaProfile?.mfaSavedBrowsers?.browsers.length > 0) {
            const { browsers } = mfaProfile.mfaSavedBrowsers;

            browsers.forEach((browser) => {
                browser.dateAdded = moment(browser.dateAdded * 1000).format('LL');
            });

            commit('LOAD_PROFILE_SAVED_BROWSERS_SUCCESS', { browsers });
        } else {
            commit('LOAD_PROFILE_NO_SAVED_BROWSERS');
        }

        commit('LOAD_PROFILE_SUCCESS', {
            firstName, lastName, username,
        });
    } catch (error) {
        const sentry = getSentry();
        sentry.captureException(error);
        commit('LOAD_PROFILE_ERROR', error);
    }
};


const removeMfa = async ({ commit }, { mfaType, currentPassword }) => {
    try {
        if (mfaType !== 'sms' && mfaType !== 'app_auth') {
            throw new Error('mfa type must be sms or app_auth');
        }

        commit('UPDATE_MFA_START');

        const url = `${import.meta.env.VITE_ACCOUNT_CENTRAL_URL}/user/me/mfa/${mfaType}`;

        const passwordConfig = {};

        passwordConfig.headers = { 'x-password': currentPassword };

        await axios.delete(url, passwordConfig);
        commit('UPDATE_MFA_SUCCESS');
    } catch (error) {
        const sentry = getSentry();
        sentry.captureException(error);
        commit('UPDATE_MFA_ERROR', error);

        if (error.response && error.response.status === 400 && error.response.data.code === 'cas.exception.account.locked') {
            commit('SET_ACCOUNT_LOCKED', true);
        }
    }
};

const setPrimary = async ({ commit }, { mfaType, currentPassword }) => {
    try {
        if (mfaType !== 'sms' && mfaType !== 'app_auth') {
            throw new Error('mfa type must be sms or app_auth');
        }

        commit('UPDATE_MFA_START');

        const url = `${import.meta.env.VITE_ACCOUNT_CENTRAL_URL}/user/me/mfa`;

        const passwordConfig = {};

        passwordConfig.headers = { 'x-password': currentPassword };

        const passwordBody = {};

        passwordBody.mfaPrimaryType = mfaType.toUpperCase();

        await axios.patch(url, passwordBody, passwordConfig);
        commit('UPDATE_MFA_SUCCESS');
    } catch (error) {
        const sentry = getSentry();
        sentry.captureException(error);
        commit('UPDATE_MFA_ERROR', error);

        if (error.response && error.response.status === 400 && error.response.data.code === 'cas.exception.account.locked') {
            commit('SET_ACCOUNT_LOCKED', true);
        }
    }
};

const deleteSavedBrowser = async ({ commit }, { mfaSavedBrowserId }) => {
    try {
        commit('DELETE_BROWSER_START');
        const url = `${import.meta.env.VITE_ACCOUNT_CENTRAL_URL}/user/me/mfa/browser/${mfaSavedBrowserId}`;

        await axios.delete(url);
        commit('DELETE_BROWSER_SUCCESS');
    } catch (error) {
        const sentry = getSentry();
        sentry.captureException(error);
        commit('DELETE_BROWSER_ERROR', error);
    }
};

const updateUserPassword = async ({ commit }, { newPassword, currentPassword }) => {
    try {
        commit('UPDATE_PASSWORD_START');

        const url = `${import.meta.env.VITE_ACCOUNT_CENTRAL_URL}/user/me/password`;

        const passwordBody = {};

        passwordBody.newPassword = newPassword;

        const passwordConfig = {};

        passwordConfig.headers = { 'x-password': currentPassword };

        await axios.post(url, passwordBody, passwordConfig);
        commit('UPDATE_PASSWORD_SUCCESS');
    } catch (error) {
        const sentry = getSentry();
        sentry.captureException(error);
        commit('UPDATE_PASSWORD_ERROR', error);

        if (error.response && error.response.status === 400 && error.response.data.code === 'cas.exception.account.locked') {
            commit('SET_ACCOUNT_LOCKED', true);
        }
    }
};

const updateUserProfile = async ({ commit }, {
    newUsername, newFirstName, newLastName,
} = {}) => {
    try {
        commit('UPDATE_PROFILE_START');
        const params = { username: newUsername, firstName: newFirstName, lastName: newLastName };
        const url = `${import.meta.env.VITE_ACCOUNT_CENTRAL_URL}/user/me/profile`;
        const response = await axios.patch(url, params);
        const { firstName, lastName, username } = response.data;

        commit('UPDATE_PROFILE_SUCCESS', {
            username, firstName, lastName,
        });
    } catch (error) {
        const sentry = getSentry();
        sentry.captureException(error);
        commit('UPDATE_PROFILE_ERROR', error);
    }
};


const getScratchCodes = async ({ commit }, { currentPassword }) => {
    try {
        commit('GET_CODES_START');

        const url = `${import.meta.env.VITE_ACCOUNT_CENTRAL_URL}/user/me/mfa/codes`;

        const passwordConfig = {};

        passwordConfig.headers = { 'x-password': currentPassword };

        const response = await axios.get(url, passwordConfig);
        const { scratchCodes, codesCreatedOn } = response.data;

        commit('GET_CODES_SUCCESS', {
            scratchCodes, codesCreatedOn,
        });
    } catch (error) {
        const sentry = getSentry();
        sentry.captureException(error);
        commit('GET_CODES_ERROR', error);

        if (error.response && error.response.status === 400 && error.response.data.code === 'cas.exception.account.locked') {
            commit('SET_ACCOUNT_LOCKED', true);
        }
    }
};

const clearCodesStatus = async ({ commit }) => {
    commit('GET_CODES_START');
};

const downloadCodes = async ({ commit }, { currentPassword }) => {
    commit('DOWNLOAD_START');

    const url = `${import.meta.env.VITE_ACCOUNT_CENTRAL_URL}/user/me/mfa/codes:download`;

    const options = { responseType: 'blob' };

    options.headers = { 'x-password': currentPassword };
    await axios.get(url, options).then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const objectURL = window.URL.createObjectURL(blob);

        const link = document.createElement('a');

        link.href = objectURL;
        link.setAttribute('download', 'KeapScratchCodes.pdf');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        commit('DOWNLOAD_SUCCESS');
    }).catch((error) => {
        const sentry = getSentry();
        sentry.captureException(error);
        commit('DOWNLOAD_ERROR', error);

        if (error.response && error.response.status === 400 && error.response.data.code === 'cas.exception.account.locked') {
            commit('SET_ACCOUNT_LOCKED', true);
        }
    });
};
